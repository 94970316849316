import { defaultPostHeaderForJsonData } from '@@api/front';
import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { ICommunityEvent, ICommunityIWEventRegisteredMember } from '@@community/data';
import { saveFile } from '@inwink/utils';
import { membersDatasourceV3 } from './members';

export function eventsQuery(
    requestManager: ICommunityRequestManager,
    tenantId: string,
    options?: IInwinkEntityV3QueryOptions
) {
    const url = "community/" + requestManager.communityId + "/" + tenantId + "/events/query";

    return requestManager.postJson<{
        data: ICommunityEvent[];
    }>(url, options && JSON.stringify(options), defaultPostHeaderForJsonData);
}

export function eventsCount(
    requestManager: ICommunityRequestManager,
    tenantId: string,
    options?: IInwinkEntityV3QueryOptions
) {
    const url = "community/" + requestManager.communityId + "/" + tenantId + "/events/count";

    return requestManager.postJson<any>(url, options && JSON.stringify(options), defaultPostHeaderForJsonData);
}

export function downloadEventICS(
    requestManager: ICommunityRequestManager,
    eventId: string,
    title: string,
    options?: IInwinkEntityV3QueryOptions
) {
    const url = `community/${requestManager.communityId}/iwevent/${eventId}/ics`;

    return requestManager.getJson(url, options).then((res: any) => {
        res.blob().then((blobContent) => {
            saveFile(blobContent, `${title}.ics`, "text/calendar");
        });
    });
}

export function registeredMembersQuery(
    requestManager: ICommunityRequestManager,
    eventId: string,
    options?: IInwinkEntityV3QueryOptions,
) {
    const targetOptions = options || {};
    return membersDatasourceV3(requestManager, `community/${requestManager.communityId}/iwevent/${eventId}/registeredmembers`)
        .then((ds) => ds.query(targetOptions));
}

export function registerEvent(
    requestManager: ICommunityRequestManager,
    eventId: string,
    data: any,
    dataInfo?: string,
    sign?: string,
): Promise<any> {
    const params = [];
    if (dataInfo) { params.push('data-info=' + dataInfo); }
    if (sign) { params.push('sign=' + sign); }
    let url = `community/${requestManager.communityId}/iwevent/${eventId}/register`;
    if (params.length) { url = url + '?' + params.join("&"); }
    return requestManager.postJson(url, JSON.stringify(data), defaultPostHeaderForJsonData);
}

export function unregisterEvent(
    requestManager: ICommunityRequestManager,
    eventId: string,
): Promise<any> {
    const url = `community/${requestManager.communityId}/iwevent/${eventId}/unregister`;

    return requestManager.postJson(url);
}

export function isRegisteredToEvent(
    requestManager: ICommunityRequestManager,
    eventId: string,
): Promise<ICommunityIWEventRegisteredMember> {
    const url = `community/${requestManager.communityId}/iwevent/${eventId}/registered`;
    return requestManager.getJson(url);
}

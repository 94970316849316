import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { PopoverDisplayContext, type IPopoverDisplayOptionsContext } from '@inwink/modals/popoverdisplaycontext';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { AiToolsContext, type IAiImageRequest, type IAiToolsContext, type IAiToolsRequest } from '@inwink/aiinputs/aitoolscontext';
import type { IDefaultEventPageProps } from '@@data/visualtheme';
import type { States } from '@@services/services';
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { actions as i18nActions } from '@@services/i18nservice';
import { modulesActions } from '@@routes/appmodules';
import { defaultPostHeaderForJsonData } from '@@api/index';
import {
    getMobileFooter,
    getPageFooter, getPageHeader,
    getVisualConfiguration, getWebmaster, getCopilot
} from '@@data/templates';
import { UrlStateContext } from '@@components/urlstatecontext';
import { ModalThemeContext } from '@@components/modalthemecontext';
import { AppHeader } from '@@components/appheader';
import { AppMenu } from '@@components/appmenu';
import AppMenuLanguagesControl from '@@components/appmenulanguages';
import { withAppTrads } from '@@components/apptrads';
import { AppUserMessage } from '@@components/appusermessage';
import { ContentInjection } from '@@components/contentinjection';
import { isFromServer } from '@@components/dynamicpage/helpers';
import { withToastProvider } from '@@components/toast/withToastProvider';
import { hasItemsInMenu } from '@@modules/helpers';
import { AppHeaderCommunity } from '../components/appheader';
import { CommunityRealtime } from '../components/communityrealtime';
import { CommunityTradsContext } from './communitytradscontext';
import { CommunityShellData } from './communitydata';
import { CommunityRoutes } from './communityroutes';

import '../../../styles/reset.less';
import '../../../styles/appthemes.less';
import '../../../styles/transitions.less';
import "../../../styles/appshell.less";

export interface ICommunityShellProps {
    community?: States.ICommunityState;
    visualstate: string[];
    location?: States.ILocation;
    match?: States.ILocationMatch;
    i18n?: States.i18nState;
    i18nActions?: typeof i18nActions;
    rootwebsite?: States.IRootWebsiteState;
    page?: States.ICurrentPageState;
    user?: States.IAppUserState;
    tracking?: States.ITrackingState;
    pageUrl?: (event: Entities.IEventDetail, url: string) => string;
    contentUrl?: (url: string) => string;
    targetEvent?: string;
    metadataMenuActions?: typeof metadataMenuActions;
    children?: React.ReactNode;
}

export interface ICommunityShellState {
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    webmaster: VisualTheme.IWebmasterConfig;
    pageheader: Entities.IContentTemplate;
    pagefooter: Entities.IContentTemplate;
    mobilefooter: Entities.IContentTemplate;
    datacontext: Entities.IPageDataContext;
    copilotConfiguration: States.ICopilotConfiguration;
    popoverOptions: IPopoverDisplayOptionsContext;
    tracking: {
        scripts: any[];
    };
}

const MobileFooter = React.lazy(() => import('@@components/mobilefooter'));

const trad = require("@inwink/tradscompanion/community");

@withAppTrads(trad)
class CommunityShellComponent extends React.Component<ICommunityShellProps, ICommunityShellState> {
    constructor(props: ICommunityShellProps) {
        super(props);

        let tracking;
        if (props.tracking && props.tracking.trackers && props.tracking.trackers.length) {
            tracking = {
                scripts: props.tracking.trackers
                    .filter((t) => t.partitionId === this.props.community?.communityid)
                    .map((t) => t.script).filter((t) => !!t)
            };
        }

        // if (__SERVERSIDE__) {
        //     if (props.match.params.lngcode && props.match.params.lngcode !== this.props.i18n.currentLanguageCode) {
        //         this.props.i18nActions.loadLanguage(props.match.params.lngcode, null, true);
        //     }
        // }

        const visualConf = this.props.community?.data ? getVisualConfiguration(this.props.community.data) : null;

        this.state = {
            visualConfiguration: visualConf,
            webmaster: this.props.community?.data ? getWebmaster(this.props.community.data) : null,
            pageheader: this.props.community?.data ? getPageHeader(this.props.community.data) : null,
            pagefooter: this.props.community?.data ? getPageFooter(this.props.community.data) : null,
            mobilefooter: this.props.community?.data ? getMobileFooter(this.props.community.data) : null,
            copilotConfiguration: this.props.community?.data ? getCopilot(this.props.community.data) : null,
            datacontext: this.getDataContext(props),
            popoverOptions: this.getPopoverOptions(visualConf),
            tracking: tracking
        };
    }

    componentDidMount(): void {
        const tmp = (global as any);
        if (typeof tmp.inwinkCommunityBootstrap !== "undefined") {
            tmp.inwinkCommunityBootstrap = undefined;
        }
    }

    getPopoverOptions(visualConfiguration: Entities.IVisualConfigurationTemplate) {
        const res: IPopoverDisplayOptionsContext = {
            modal: {
                containerClassName: "community-" + this.props.community.communityid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.modalTheme ? " bloctheme-" + visualConfiguration.global.modalTheme : "")
            },
            popover: {
                containerClassName: "community-" + this.props.community.communityid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.popoverTheme ? " bloctheme-" + visualConfiguration.global.popoverTheme : "")
            },
            sidemodal: {
                containerClassName: "community-" + this.props.community.communityid,
                modalClassName: "bloctheme"
                    + (visualConfiguration?.global?.modalTheme ? " bloctheme-" + visualConfiguration.global.modalTheme : "")
            }
        };
        return res;
    }

    getDataContext(props: ICommunityShellProps) : Entities.IPageDataContext {
        const res: Entities.IPageDataContext = {
            communitystate: props.community,
            event: null, // props.event?.detail,
            eventstate: null, // props.event,
            userstate: props.user,
            user: props.user.currentUser ? props.user.currentUser.detail : null,
            i18nstate: props.i18n,
            fieldtemplate: null
        };

        return res;
    }

    componentDidUpdate(prevprops: ICommunityShellProps, prevstate: ICommunityShellState) {
        let patch: Partial<ICommunityShellState> = null;

        if (this.props.tracking
            && prevprops.tracking !== this.props.tracking
            && this.props.tracking.trackers
            && this.props.tracking.trackers.length
        ) {
            patch = patch || {};
            patch.tracking = {
                scripts: this.props.tracking.trackers
                    .filter((t) => t.partitionId === this.props.community?.communityid)
                    .map((t) => t.script).filter((t) => !!t)
            };
        }

        if (this.props.community) {
            if (prevprops.community !== this.props.community || !this.state.visualConfiguration || !this.state.webmaster) {
                const visualconfig = getVisualConfiguration(this.props.community.data);
                if (visualconfig && prevstate.visualConfiguration !== visualconfig) {
                    patch = patch || {};
                    patch.visualConfiguration = visualconfig;
                    patch.popoverOptions = this.getPopoverOptions(visualconfig);
                }

                const webmaster = getWebmaster(this.props.community.data);
                if (webmaster && webmaster !== prevstate.webmaster) {
                    patch = patch || {};
                    patch.webmaster = webmaster;
                }

                const pageheader = getPageHeader(this.props.community.data);
                if (pageheader && pageheader !== prevstate.pageheader) {
                    patch = patch || {};
                    patch.pageheader = pageheader;
                }
                const pagefooter = getPageFooter(this.props.community.data);
                if (pagefooter && pagefooter !== prevstate.pagefooter) {
                    patch = patch || {};
                    patch.pagefooter = pagefooter;
                }

                const mobilefooter = getMobileFooter(this.props.community.data);
                if (mobilefooter && mobilefooter !== prevstate.mobilefooter) {
                    patch = patch || {};
                    patch.mobilefooter = mobilefooter;
                }

                const copilot = getCopilot(this.props.community.data);
                if (copilot && copilot !== prevstate.copilotConfiguration) {
                    patch = patch || {};
                    patch.copilotConfiguration = copilot;
                }
            }
        }

        if (this.props.community !== prevprops.community || this.props.i18n !== prevprops.i18n) {
            patch = patch || {};
            patch.datacontext = this.getDataContext(this.props);
        }

        if ((this.props.user !== prevprops.user) && this.props.user.currentUser) {
            patch = patch || {};
            patch.datacontext = this.getDataContext(this.props);
        }

        if (patch) {
            this.setState(patch as any);
        }
    }

    loadMenu = () => {
        this.props.metadataMenuActions.loadMenu();
    };

    toggleMenu = () => {
        this.props.metadataMenuActions.toggleMenu();
    };

    render() {
        let header = null;
        let menu = null;
        let burgerbtn = null;
        let footer;
        const isServerSide = __SERVERSIDE__;

        // const classNames = ["app-shell", "community-" + this.props.community.communityid];
        const pageArgs: IDefaultEventPageProps = {
            // key: key,
            location: this.props.location,
            match: this.props.match,
            community: this.props.community,
            user: this.props.user,
            i18n: this.props.i18n,
            webmasterConfiguration: this.state.webmaster,
            visualConfiguration: this.state.visualConfiguration,
            page: this.props.page,
            pagefooter: this.state.pagefooter,
            visualstate: this.props.visualstate
        };

        const classes = ["app-layout"];

        const displayBurgerBtn = hasItemsInMenu(this.state.pageheader);

        if (this.props.community.detail) {
            const menuStyle = {
                backgroundColor: "transparent",
                color: this.state.visualConfiguration?.appHeader?.style?.color || ""
            };
            header = <AppHeader
                visualConfiguration={this.state.visualConfiguration}
                headerSettings={this.props.community?.detail?.configuration?.companion?.headerSettings}
                headerTemplate={this.state.pageheader}
                datacontext={this.state.datacontext}
                hasBurgerBtn={displayBurgerBtn}
            >
                <AppHeaderCommunity
                    visualConfiguration={this.state.visualConfiguration}
                    headerTemplate={this.state.pageheader}
                    headerSettings={this.props.community?.detail?.configuration?.companion?.headerSettings}
                    datacontext={this.state.datacontext}
                    hasBurgerBtn={displayBurgerBtn}
                />
            </AppHeader>;
            menu = <AppMenu
                {...pageArgs}
                menuTemplate={this.state.pageheader}
                onHide={this.toggleMenu}
                datacontext={this.state.datacontext}
            >
                <AppMenuLanguagesControl />
            </AppMenu>;

            burgerbtn = <div
                className="burger-menu"
                id="burger-menu"
                onMouseEnter={this.loadMenu}
                onClick={this.toggleMenu}
                style={menuStyle}
            >
                <i className="inwink-menu" />
            </div>;
                        
            if (!isFromServer() && this.state.mobilefooter && this.props.visualstate
                && (!(this.props.visualstate.indexOf("M") >= 0))) {
                classes.push("with-mobilefooter");
                if (!__SERVERSIDE__) {
                    footer = <React.Suspense fallback={<></>}>
                        <MobileFooter
                            visualConfiguration={this.state.visualConfiguration}
                            footerTemplate={this.state.mobilefooter}
                            user={this.props.user}
                            i18n={this.props.i18n}
                            location={this.props.location}
                            match={this.props.match}
                        />
                    </React.Suspense>;
                }
            }
        }

        return <PopoverDisplayContext.Provider value={this.state.popoverOptions}>
            
            <UrlStateContext
                baseUrl={this.props.match?.url}
                i18n={this.props.i18n}
                noLanguage={!!this.props.match?.params?.lngcode}
            >
                <CommunityTradsContext>
                    <CommunityAiTools {...this.props}>
                        <CommunityShellData
                            community={this.props.community}
                            visualConfiguration={this.state.visualConfiguration}
                            location={this.props.location}
                            match={this.props.match}
                            i18n={this.props.i18n}
                        >
                            <div className={classes.join(' ')}>
                                <Helmet />
                                <ModalThemeContext
                                    visualConfiguration={this.state.visualConfiguration}
                                    prefix={"community-" + this.props.community.communityid}
                                >
                                    <CommunityRealtime>
                                        <div className="app-content">
                                            {header}
                                            <CommunityRoutes
                                                {...this.props}
                                                copilotConfiguration={this.state.copilotConfiguration}
                                                visualConfiguration={this.state.visualConfiguration}
                                                webmasterConfiguration={this.state.webmaster}
                                                pagefooter={this.state.pagefooter}
                                                modulesActions={modulesActions}
                                            />
                                            {footer}
                                        </div>
                                        <AppUserMessage />
                                        {displayBurgerBtn && burgerbtn}
                                        {menu}
                                    </CommunityRealtime>
                                </ModalThemeContext>
                            </div>
                            <ContentInjection id="webmaster" inject={this.state.webmaster?.afterBody} serverSide={isServerSide} />
                            <ContentInjection id="tracking" inject={this.state.tracking} serverSide={isServerSide} />
                        </CommunityShellData>
                    </CommunityAiTools>
                </CommunityTradsContext>
            </UrlStateContext>
        </PopoverDisplayContext.Provider>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        community: state.community,
        rootwebsite: state.rootwebsite,
        user: state.user,
        page: state.pages.currentPage,
        i18n: state.i18n,
        tracking: state.tracking
    };
}

function mapDispatchToProps(dispatch) {
    return {
        modulesActions: bindActionCreators(modulesActions, dispatch),
        metadataMenuActions: bindActionCreators(metadataMenuActions, dispatch),
        i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}

export const CommunityShellContent = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withToastProvider(CommunityShellComponent) as any) as any);


function AppShellCommunityLanguages(props: ICommunityShellProps) {
    if (props.community?.detail?.configuration?.global?.supportedLanguages
            && props.community.detail.configuration.global.supportedLanguages.length > 1) {
        const rootPath = (props.match.path) || "";
        let lngRootPath = rootPath;
        if (lngRootPath === "/") {
            lngRootPath = "";
        }
        const routes = props.community.detail.configuration.global.supportedLanguages.map((lng) => {
            return <Route
                key={lng}
                path={lngRootPath + "/" + lng}
                render={(rprops) => {
                    const routeprops = rprops;
                    routeprops.match.path = lngRootPath + "/:lngcode";
                    routeprops.match.params.lngcode = lng;
                    return <CommunityShellContent {...props} {...routeprops} />;
                }}
            />;
        });
        routes.push(<Route
            key="default"
            path={rootPath}
            render={(rprops) => {
                const routeprops = rprops;
                return <CommunityShellContent {...props} {...routeprops} />;
            }}
        />);
        return <Switch location={props.location}>
            {routes}
        </Switch>;
    } else {
        return <CommunityShellComponent {...props} />;

    }
}

export const CommunityShell = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withToastProvider(AppShellCommunityLanguages) as any) as any);
    
export default CommunityShell;

export function CommunityAiTools(props: {
    community?: States.ICommunityState,
    i18n?: States.i18nState, 
    children: any
}) {
    const [aiContext, setAiContext] = React.useState<IAiToolsContext>(null);
    React.useEffect(() => {
        const customerId = props.community?.detail?.customerId;
        const tenantId = props.community?.detail?.tenantId;
        const communityId = props?.community?.communityid;
    
        if (customerId && tenantId && communityId && props.community?.detail?.features?.ai === true) {
            const aiToolsRequest = (request: IAiToolsRequest) => {
                const bcontext = [];
                if (props.community.detail?.name){
                    bcontext.push("**Community Name:** " + props.community.detail?.name);
                }
                const lng = props.community.detail?.configuration?.global?.defaultLanguage;
                if (lng && props.community.detail.description && props.community.detail.description[lng]) {
                    bcontext.push("**Community Description (in '" + lng + "')**" 
                        + props.community.detail.description[lng]);                        
                }
                if (request.businessContext){
                    bcontext.push(request.businessContext);
                }

                return props?.community?.requestManagers.apiFront.postJson<Response>(
                    `aitools/community/${customerId}/${tenantId}/${communityId}`, 
                    JSON.stringify({
                        currentUserLanguage: props.i18n.currentLanguageCode,
                        ...request,
                        businessContext: bcontext.join("\r\n")
                    }), 
                    defaultPostHeaderForJsonData);
            };

            const generateImage = (request: IAiImageRequest) => {
                return props?.community?.requestManagers.apiFront.postJson(
                    `aitools/community/${customerId}/${tenantId}/${communityId}/image`, 
                    JSON.stringify(request), 
                    defaultPostHeaderForJsonData) as any;
            };
    
            setAiContext({ sendRequest: aiToolsRequest, generateImage: generateImage });            
        }
    }, [props?.community?.communityid, props.community?.detail?.features?.ai]);    

    return <AiToolsContext.Provider value={aiContext}>{
        props.children
    }</AiToolsContext.Provider>;
}
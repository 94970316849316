import { Entities } from '@inwink/entities/entities';
import { userMessageActions } from '@@services/usermessageactions';
import { type IMemberContactRequest, type IMember, type ICommunityDiscussion, createTmpDiscussion } from "@@community/data";
import type { IMemberInfoProps } from "./components/modals/memberinfo";
import type { IContactRequestProps } from "./components/modals/contactrequest";
import type { INewContactRequestProps } from "./components/modals/newcontactrequest";
import type { IDiscussionMessagesProps } from "./components/messages/discussionmessages";
import type { IDiscussionsListProps } from './components/messages/discussionslist';
import type { PopoverAdvancedOptions } from '@inwink/modals/popover';
import type { IDeleteContactProps } from './components/modals/deletecontact';

export const networkingActions = {

    addContactRequest: (i18nHelper: Entities.i18nHelper, member: IMember) => {
        return (dispatch, getState) => {
            return import("./components/modals/newcontactrequest").then((mod) => {
                const state = getState();
                const props: INewContactRequestProps = {
                    member,
                    i18nHelper,
                    user: state.user,
                    community: state.community
                };

                return userMessageActions.showModal(
                    i18nHelper,
                    mod.NewContactRequest,
                    "community.networking.contactrequest.title",
                    props,
                    "addcontactrequest-modal"
                )(dispatch, getState);
            });
        };
    },

    showMember: (i18nHelper: Entities.i18nHelper, elt: HTMLElement, memberId: string,
        options?: Partial<IMemberInfoProps>, member?: IMember, targetMember?: IMember) => {
        return (dispatch, getState) => {
            return import("./components/modals/memberinfo").then((mod) => {
                const state = getState();
                const props: IMemberInfoProps = {
                    memberId,
                    i18nHelper,
                    user: state.user,
                    community: state.community,
                    parentElt: elt,
                    member,
                    targetMember,
                    ...options
                };

                return userMessageActions.showMessage(
                    i18nHelper,
                    mod.MemberInfo,
                    props,
                    elt,
                    null,
                    null,
                    "memberinfo-modal"
                )(dispatch);
            });
        };
    },

    showContactRequest: (i18nHelper: Entities.i18nHelper, contactrequest: IMemberContactRequest) => {
        return (dispatch, getState) => {
            return import("./components/modals/contactrequest").then((mod) => {
                const state = getState();
                const props: IContactRequestProps = {
                    contactrequest,
                    i18nHelper,
                    user: state.user,
                    community: state.community
                };

                return userMessageActions.showModal(
                    i18nHelper,
                    mod.ContactRequest,
                    "community.networking.contactrequest.title",
                    props,
                    "contactrequest-modal"
                )(dispatch, getState);
            });
        };
    },

    showDiscussion: (
        parentElement: HTMLElement,
        i18nHelper: Entities.i18nHelper,
        discussionId: string,
        options?: PopoverAdvancedOptions,
        targetMember?: IMember,
        onCompleted?: (res?) => void,
        onDiscussionChanged?: (update: ICommunityDiscussion) => void // Ajout du paramètre ici
    ) => {
        return (dispatch, getState) => {
            return import("./components/messages/discussionmessages").then((mod) => {
                const state = getState();
                const props: IDiscussionMessagesProps = {
                    discussionId,
                    user: state.user,
                    community: state.community
                };
    
                if (targetMember && props.user?.currentUser?.member) {
                    // On crée une discussion temporaire
                    props.discussion = createTmpDiscussion(props.user.currentUser.member, targetMember);
                }
    
                return userMessageActions.showMessage(
                    i18nHelper,
                    mod.DiscussionMessages,
                    props,
                    parentElement,
                    null,
                    null,
                    "discussion-modal",
                    options
                )(dispatch).then((r) => {
                    if (onCompleted) {
                        onCompleted();
                    }
                    const res = r as IShowDiscussionRes;
                    if (res?.update && onDiscussionChanged) {
                        onDiscussionChanged(res.update);
                    }
    
                    return res;
                });
            });
        };
    },

    showDiscussions: (parentElement: HTMLElement, i18nHelper: Entities.i18nHelper) => {
        return (dispatch, getState) => {
            return import("./components/messages/discussionslist").then((mod) => {
                const state = getState();
                const props: IDiscussionsListProps = {
                    user: state.user,
                    community: state.community,
                    selectedDiscussion: null,
                    onDiscussionSelected: (discussion, hideDiscussionList, onDiscussionChanged) => {
                        // dispatch(userMessageActions.hideMessage());
                        const promise = networkingActions
                            .showDiscussion(parentElement, i18nHelper, discussion.id, {
                                disableOverlayDismiss: true,
                                hasEnabledMouseOutOfModal: true
                            })(dispatch, getState);
                        promise.then((r: any) => {
                            const res = r as IShowDiscussionRes;
                            if (res?.closeAll && hideDiscussionList) {
                                hideDiscussionList();
                            }
                            if (res?.update && onDiscussionChanged) {
                                onDiscussionChanged(res.update);
                            }
                        });
                    },
                };

                return userMessageActions.showMessage(
                    i18nHelper,
                    mod.DiscussionsList,
                    props,
                    parentElement,
                    null,
                    null,                   
                    "discussionlist-modal",
                    {
                        disableOverlayDismiss: true,
                        hasEnabledMouseOutOfModal: true
                    },
                )(dispatch);
            });
        };
    },

    showDeleteContact: (i18nHelper: Entities.i18nHelper, contactId: string, member: IMember, onCompleted: (res: any) => void) => {
        return (dispatch, getState) => {
            return import("./components/modals/deletecontact").then((mod) => {
                const state = getState();
                const props: IDeleteContactProps = {
                    contactId,
                    member,
                    i18nHelper,
                    user: state.user,
                    community: state.community,
                    onCompleted: (res) => {
                        if (onCompleted) {
                            onCompleted(res);
                        }
                    }
                };
    
                return userMessageActions.showModal(
                    i18nHelper,
                    mod.DeleteContactPopup,
                    "community.member.contact.delete.title",
                    props,
                    "deletecontact-modal"
                )(dispatch, getState);
            });
        };
    },
};

export interface IShowDiscussionRes {
    closeAll?: boolean;
    update?: ICommunityDiscussion;
}